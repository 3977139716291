import React from 'react';

function UserDetailsDisplay(props: any) {
    return (
        <div>
            {props.userEmail != null && props.userEmail.length > 0 &&
                <div className="text-box">
                    Logged in to API as {props.userEmail}
                </div>
            }
        </div>
    );
}

export default UserDetailsDisplay;