import React from 'react';
import LoginButton from "../../components/LoginButton/button"

function Welcome(props: any) {
    return (
      <div>
        Welcome! Please Log In
        <LoginButton setUserEmail = {props.setUserEmail} setLoading = {props.setLoading}/>
      </div>
    );
}

export default Welcome;