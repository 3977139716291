import axios from "axios";
import env from '../../env.json';

const requestOverview = async (setOverview: React.Dispatch<React.SetStateAction<string | null>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const httpClient = axios.create({
        headers: {
          'Content-Type': 'application/json'
        },
        validateStatus: () => {
          // don't use exceptions to check status; we'll check return code manually
          return true;
        }
    });
    
    const token = localStorage.getItem("token");

    httpClient.defaults.baseURL = env.ORDER_API;

    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;

    setLoading(true);
    try {
        const result = await httpClient.get('worklist/overview');
        setOverview(JSON.stringify(result.data));
        setLoading(false);
        return {
          success: result.status === 200,
          data: result.data
        };
    } catch (error: any) {
        setLoading(false);
        return {
            success: false,
            message: error.toString()
        };
    }
}

export default requestOverview;