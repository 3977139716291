import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './pages/Home/home';
import Welcome from './pages/Welcome/welcome';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import logo from "./assets/images/logo-full.png"
import login from "./components/Utils/msalLogin";
import LoadingSpinner from './components/LoadingSpinner/loadingSpinner';

function App() {
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail'));
  const [overview, setOverview] = useState([]);
  const [loading, setLoading] = useState(false);

  const haloAccessTokenIsExpired = (expiryTime: string) => {
    const expiryTimeAsNumber = new Date(expiryTime).getTime();
    const currentTime = new Date().getTime();
    return (currentTime > expiryTimeAsNumber);
  };

  const handleTokenExpiry = async () => {
    if (haloAccessTokenIsExpired(localStorage.getItem('tokenExpiryTime') || "")){
      login(setUserEmail, setLoading);
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleTokenExpiry);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleTokenExpiry);
    };
  });

  return (
    <>
      <img src={logo} width={500} height={100} alt="Ultromics EchoGo Portal" />
      {loading &&
        <LoadingSpinner/>
      }
      {!loading &&
        <>
          <AuthenticatedTemplate>
            <Home
              userEmail = {userEmail}
              setUserEmail = {setUserEmail}
              overview = {overview}
              setOverview = {setOverview}
              setLoading = {setLoading}
            />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Welcome setLoading = {setLoading}/>
          </UnauthenticatedTemplate>
        </>
      }
    </>
  );
}

export default App;
