import React from 'react';

function overviewDisplay(props: any) {
    return (
        <div className="text-box">
            {props.overview}
        </div>
    );
}

export default overviewDisplay;