import React from 'react';
import login from "../Utils/msalLogin";

function Button(props: any) {
    const handleLogin = async () => {
        console.log("Handling login");
        try {
          await login(props.setUserEmail, props.setLoading);
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <button onClick={handleLogin}>
            Log In
        </button>
    );
}

export default Button;