import { msalInstance } from "./msalConfig";

const logout = async () => {
    const userEmail = localStorage.getItem("userEmail");
    msalInstance.logoutPopup({
        account: msalInstance.getAccountByUsername(userEmail || "")
    });

    localStorage.setItem("userEmail", "");
    localStorage.setItem("token", "");
}

export default logout;