import * as msal from "@azure/msal-browser";
import env from '../../env.json';

const loginRequest = {
    scopes: ["User.Read"]
};

const msalConfig = {
    auth: {
        clientId: env.MSAL_CLIENTID,
        authority: env.MSAL_AUTHORITY,
        redirectUri: env.MSAL_REDIRECTURL,
        postLogoutRedirectUri: `${env.MSAL_REDIRECTURL}welcome`,
    }
};

const msalInstance = new msal.PublicClientApplication(msalConfig)

export {msalInstance, loginRequest};