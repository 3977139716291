import { msalInstance, loginRequest } from "./msalConfig";

const handleRedirect = async () => {
    msalInstance.handleRedirectPromise()
    .then(response => {
        if (!response) {
            return;
        }
        localStorage.setItem("token", response.accessToken);
    })
    .catch((error) => {
        console.error(error); // eslint-disable-line
    });
}

const login = async (setUserEmail: React.Dispatch<React.SetStateAction<string | null>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading (true);
    await msalInstance.initialize();
    const response = await msalInstance.loginPopup(loginRequest);
    localStorage.setItem("token", `${response.accessToken}`);
    const expiresOn = response.expiresOn?.toString() || "";
    localStorage.setItem("tokenExpiryTime", expiresOn);
    setLoading (false);

    const allAccounts = msalInstance.getAllAccounts();
    if (allAccounts?.length > 0) {
        localStorage.setItem("userEmail", allAccounts[0].username);
        setUserEmail(allAccounts[0].username);
    } else {
        handleRedirect();
    }
}

export default login;