import React from 'react';
import requestOverview from "../Utils/requestOverview";

function Button(props: any) {
    const handleOverviewRequest = async () => {
        console.log("Requesting overview from Order API");
        try {
          await requestOverview(props.setOverview, props.setLoading);
        } catch (error) {
          console.error(error);
        }
    };

    return (
        <button onClick={handleOverviewRequest}>
            Request Overview
        </button>
    );
}

export default Button;