import React from 'react';
import logo from "../../assets/images/logo-icon-128.png"
import './loadingSpinner.css';

function LoadingSpinner(props: any) {
    return (
      <div className="loading-div">
        <img src={logo} alt="Loading Spinner" className="img" width="80" height="80" />
      </div>
    );
}

export default LoadingSpinner;