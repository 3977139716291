import React from 'react';
import logout from "../Utils/msalLogout";

function LogoutButton() {
    const handleLogout = async () => {
        console.log("Handling logout");
        try {
          await logout();
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <button onClick={handleLogout}>
            Log Out
        </button>
    );
}

export default LogoutButton;