import React from 'react';
import LogoutButton from "../../components/LogoutButton/button"
import RequestOverviewButton from "../../components/RequestOverviewButton/button"
import UserDetailsDisplay from "../../components/UserDetailsDisplay/userDetailsDisplay"
import OverviewDisplay from "../../components/OverviewDisplay/overviewDisplay"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

function Home(props: any) {
    return (
      <Container>
        <Row>
            <UserDetailsDisplay userEmail = {props.userEmail}/>
        </Row>
        <Row>
            <LogoutButton/>
        </Row>
        <Row>
            <RequestOverviewButton setOverview = {props.setOverview} setLoading = {props.setLoading}/>
        </Row>
        <Row>
            <OverviewDisplay overview = {props.overview}/>
        </Row>
      </Container>
    );
}

export default Home;